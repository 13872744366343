import { Button, Input, Select, SelectItem, Textarea } from "@heroui/react";
import { Formik, Form } from "formik";
import { Customer, Text, client } from "../../utils/client";
import { useModal } from "../../hooks/useModal";
import SelectUser from "./Fields/SelectUser";

type ServiceFormProps = {
  customer: Customer;
};

export default function ServiceForm({ customer }: ServiceFormProps) {
  const { closeModal } = useModal();

  const onSubmit = async (data: any) => {
    await client.service("services").create({
      ...data,
      customerId: customer._id,
    });
    closeModal();
  };

  const types = [
    { key: "seo", label: "newcustomers SEO" },
    { key: "ads", label: "newcustomers SEM" },
    { key: "cro", label: "newcustomers CRO" },
    { key: "hosting", label: "newcustomers Hosting" },
  ];

  return (
    <Formik
      initialValues={{
        active: true,
        value: 0,
        period: 12,
        description: "",
        type: "seo",
        userId: "",
        wincherId: "",
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, handleChange, handleBlur, handleSubmit, dirty }) => (
        <Form onSubmit={handleSubmit}>
          <div className="space-y-2">
            <Select name="type" onChange={handleChange} label="Typ" selectedKeys={[values.type]} value={values.type}>
              {types.map((type) => (
                <SelectItem textValue={type.label} key={type.key}>
                  {type.label}
                </SelectItem>
              ))}
            </Select>
            <Textarea
              fullWidth
              name="description"
              labelPlacement="inside"
              variant="flat"
              type="text"
              label="Beskrivning"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="value"
              labelPlacement="inside"
              variant="flat"
              type="number"
              label="Värde"
              value={values.value ? values.value.toString() : "0"}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {values.type === "seo" && (
              <Input
                fullWidth
                name="wincherId"
                labelPlacement="inside"
                variant="flat"
                type="text"
                label="Wincher id"
                value={values.wincherId}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )}

            <SelectUser
              name="userId"
              label="Produktionsansvarig"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.userId}
            />
          </div>

          <div className="flex justify-end mt-5 space-x-5">
            {dirty && (
              <Button type="submit" color="primary">
                Spara
              </Button>
            )}
            <Button onClick={closeModal} type="button">
              Avbryt
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
