import { useEffect, useState } from "react";
import { Customer, Project, client } from "../../utils/client";
import { Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@heroui/react";
import { useRenderCell } from "../../hooks/useRenderCell";
import { useNavigate } from "react-router-dom";

type CustomerProjectsProps = {
  customer: Customer;
};
export default function CustomerProjects({ customer }: CustomerProjectsProps) {
  const [projects, setProjects] = useState<Project[]>([]);
  const renderCell = useRenderCell();
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch customer services
    const fetchProjects = async () => {
      // @ts-ignore
      const projects = await client.service("projects").find({ query: { customerId: customer._id.toString() } });
      setProjects(projects.data);
    };
    fetchProjects();
  }, [customer]);

  const columns = [
    { name: "PROJEKT", uid: "service" },
    { name: "", uid: "usericon" },
  ];

  return (
    <Table
      className="panel"
      classNames={{
        wrapper: "shadow-none",
      }}
      selectionMode="single"
      aria-label="Customer services"
    >
      <TableHeader columns={columns}>
        {(column) => (
          <TableColumn key={column.uid} align={column.uid === "actions" ? "center" : "start"}>
            {column.name}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody emptyContent={"Inga projekt tillagda"} items={projects}>
        {(project) => (
          <TableRow
            onClick={() => navigate("/services/" + project._id.toString())}
            className="cursor-pointer"
            key={project._id.toString()}
          >
            {(columnKey: any) => <TableCell>{renderCell(project, columnKey)}</TableCell>}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
