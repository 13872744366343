import { Avatar } from "@heroui/react";
import React from "react";

type StatsPanelProps = {
  title: string;
  value: string | number;
  color?: string;
  icon?: React.ReactNode;
};

function classNames(...classes: (string | boolean | undefined)[]) {
  return classes.filter(Boolean).join(" ");
}

export default function StatsPanel({ title, value, color, icon }: StatsPanelProps) {
  return (
    <div className="p-6 rounded-lg panel">
      <div className="flex gap-3">
        <Avatar
          size="md"
          icon={icon}
          classNames={{
            base: "bg-background shrink-0",
            icon: "p-2 text-primary",
          }}
          className="my-auto"
        />
        <div>
          <div className={classNames(`text-sm font-light text-gray`)}>{title}</div>
          <div className="text-xl font-medium text-gray">{value}</div>
        </div>
      </div>
    </div>
  );
}
