import { Select, SelectItem, Avatar } from "@heroui/react";
import { DataContext } from "../../context/DataContext";
import { useContext } from "react";

type SelectCustomerProps = {
  onSelect: (customerId: string | null) => void;
};

export default function SelectCustomer({ onSelect }: SelectCustomerProps) {
  const { customers } = useContext(DataContext);

  return (
    <Select
      aria-label={"Välj kund"}
      classNames={{
        base: "max-w-xs",
        trigger: "h-12",
      }}
      onSelectionChange={(keys) => {
        const selected = Array.from(keys)[0];
        if (!selected || selected === "all") {
          onSelect(null);
        } else {
          onSelect(selected as string);
        }
      }}
      items={customers.data}
      placeholder="Välj kund"
      renderValue={(items) => {
        return items.map((item) => (
          <div key={item.key} className="flex items-center gap-2">
            <Avatar alt={item.data?.name} className="flex-shrink-0" size="sm" src={item.data?.favicon ?? ""} />
            <div className="flex flex-col">
              <span>{item.data?.name}</span>
              <span className="text-default-500 text-tiny">{item.data?.website}</span>
            </div>
          </div>
        ));
      }}
    >
      {(customer) => (
        <SelectItem key={customer._id.toString()} textValue={customer.name}>
          <div className="flex items-center gap-2">
            <Avatar alt={customer.name} className="flex-shrink-0" size="sm" src={customer.favicon ?? ""} />
            <div className="flex flex-col">
              <span className="text-small">{customer.name}</span>
              <span className="text-tiny text-default-400">{customer.website}</span>
            </div>
          </div>
        </SelectItem>
      )}
    </Select>
  );
}
