import { Avatar, Button, Input, Select, SelectedItems, SelectItem, Textarea } from "@heroui/react";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup"; // Import Yup for validation
import { Customer, Task, client } from "../../utils/client";
import { useModal } from "../../hooks/useModal";
import SelectUser from "./Fields/SelectUser";
import { useContext } from "react";
import { DataContext } from "../../context/DataContext";
import DatePicker from "./Fields/DatePicker";
import { useAuth } from "../../context/AuthContext";

type TaskFormProps = {
  task?: Task; // Made task optional
  customerId?: string;
};

// Define validation schema using Yup
const validationSchema = Yup.object().shape({
  customerId: Yup.string().required("Kund är obligatoriskt"),
  text: Yup.string().required("Text är obligatoriskt"),
  assignedTo: Yup.string().required("Tilldelning är obligatoriskt"),
  deadline: Yup.number().nullable(), // Make deadline optional
});

// Function to calculate the next Friday date
function getNextFridayDate(): number {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const daysUntilNextFriday = ((5 - dayOfWeek + 7) % 7) + 7; // Calculate days until next week's Friday
  const nextFriday = new Date(today);
  nextFriday.setDate(today.getDate() + daysUntilNextFriday);
  nextFriday.setHours(0, 0, 0, 0); // Set time to midnight
  return nextFriday.getTime(); // Return as Unix timestamp
}

export default function TaskForm({ task, customerId }: TaskFormProps) {
  const { user } = useAuth();
  const { closeModal } = useModal();
  const { customers } = useContext(DataContext);

  const isNewTask = !task || !task._id;

  const onSubmit = async (data: any) => {
    try {
      if (isNewTask) {
        await client.service("tasks").create(data);
      } else {
        if (data.completed && !task.closedAt) {
          data.closedAt = new Date().getTime();
        }
        await client.service("tasks").patch(task._id.toString(), data);
      }
      closeModal();
    } catch (error) {
      console.error("Error saving task", error);
    }
  };

  const onDelete = async () => {
    try {
      if (!isNewTask && task) {
        await client.service("tasks").remove(task._id.toString());
        closeModal();
      }
    } catch (error) {
      console.error("Error deleting task", error);
    }
  };

  return (
    <Formik
      initialValues={{
        customerId: task?.customerId || customerId || "",
        text: task?.text || "",
        description: task?.description || "",
        timeGiven: task?.timeGiven || 0,
        timeTaken: task?.timeTaken || 0,
        assignedTo: task?.assignedTo || "",
        createdBy: task?.createdBy || user?._id,
        completed: task?.completed || false,
        // If task is new, set deadline to next Friday, otherwise use task's deadline or null
        deadline: task?.deadline || (isNewTask ? getNextFridayDate() : null),
      }}
      validationSchema={validationSchema} // Add validation schema to Formik
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, errors, touched, dirty }) => (
        <Form onSubmit={handleSubmit}>
          <div className="space-y-2">
            {!customerId && (
              <Select
                name="customerId"
                items={customers.data}
                label="Välj kund"
                variant="flat"
                size="lg"
                placeholder="Välj kund"
                selectedKeys={[values.customerId?.toString() || ""]}
                onChange={handleChange}
                onBlur={handleBlur}
                renderValue={(customers: SelectedItems<Customer>) => {
                  return customers.map((customer) => (
                    <div key={customer.data?._id.toString()} className="flex items-center gap-2">
                      <Avatar
                        alt={customer.data?.name}
                        className="flex-shrink-0"
                        size="sm"
                        src={customer.data?.favicon ?? undefined}
                      />
                      <div className="flex flex-col">
                        <span>{customer.data?.name}</span>
                      </div>
                    </div>
                  ));
                }}
              >
                {(customer) => (
                  <SelectItem textValue={customer.name} key={customer._id.toString()}>
                    <div className="flex items-center gap-2">
                      <Avatar
                        alt={customer.name}
                        className="flex-shrink-0 my-2"
                        size="sm"
                        src={customer.favicon ?? undefined}
                      />
                      <div className="flex flex-col">
                        <span className="text-small">{customer.name}</span>
                        <span className="text-tiny text-default-400">{customer.website}</span>
                      </div>
                    </div>
                  </SelectItem>
                )}
              </Select>
            )}
            <Input
              fullWidth
              name="text"
              labelPlacement="inside"
              variant="flat"
              type="text"
              label="Text"
              value={values.text}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Textarea
              fullWidth
              name="description"
              labelPlacement="inside"
              variant="flat"
              type="text"
              label="Beskrivning"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="timeGiven"
              labelPlacement="inside"
              variant="flat"
              type="number"
              label="Planerade timmar"
              value={values.timeGiven?.toString() || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {task?._id && (
              <Input
                fullWidth
                name="timeTaken"
                labelPlacement="inside"
                variant="flat"
                type="number"
                label="Arbetade timmar"
                value={values.timeTaken?.toString() || ""}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            )}
            <DatePicker
              value={values.deadline}
              label="Deadline"
              onChange={(newTimestamp) => setFieldValue("deadline", newTimestamp)} // Update Formik's value
            />
            {values.deadline && (
              <Button size="sm" onClick={() => setFieldValue("deadline", null)}>
                Ta bort deadline
              </Button>
            )}
            <SelectUser
              name="assignedTo"
              label="Tilldelad"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.assignedTo}
            />
          </div>

          {/* Error Messages */}
          <div className="my-2 text-xs text-red-500">
            <ErrorMessage name="customerId" component="div" className="text-red-500" />
            <ErrorMessage name="text" component="div" className="text-red-500" />
            <ErrorMessage name="assignedTo" component="div" className="text-red-500" />
            <ErrorMessage name="deadline" component="div" className="text-red-500" />
          </div>

          <div className="flex justify-end mt-5 space-x-5">
            {!dirty && !isNewTask && (
              <Button onClick={onDelete} type="button" color="danger">
                Ta bort
              </Button>
            )}
            {dirty && (
              <Button type="submit" color="primary">
                Spara
              </Button>
            )}
            <Button onClick={closeModal} type="button">
              Avbryt
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
