import { Button, Input } from "@heroui/react";
import { Formik, Form } from "formik";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../context/DataContext";
import * as Yup from "yup";
import { client } from "../../utils/client";

type KpiField = {
  start: number;
  goal: number;
};

type KpiFormValues = {
  conversions: KpiField;
  conversionRate: KpiField;
  sessions: KpiField;
  engagementRate: KpiField;
  userEngagementDuration: KpiField;
};

type KpiFormProps = {
  customerId: string;
  kpiType: "seo" | "sem" | "cro";
};

const validationSchema = Yup.object().shape({
  conversions: Yup.object({
    start: Yup.number().required("Start is required"),
    goal: Yup.number().required("Goal is required"),
  }),
  conversionRate: Yup.object({
    start: Yup.number().required("Start is required"),
    goal: Yup.number().required("Goal is required"),
  }),
  sessions: Yup.object({
    start: Yup.number().required("Start is required"),
    goal: Yup.number().required("Goal is required"),
  }),
  engagementRate: Yup.object({
    start: Yup.number().required("Start is required"),
    goal: Yup.number().required("Goal is required"),
  }),
  userEngagementDuration: Yup.object({
    start: Yup.number().required("Start is required"),
    goal: Yup.number().required("Goal is required"),
  }),
});

export default function KpiForm({ customerId, kpiType }: KpiFormProps) {
  const { customers } = useContext(DataContext);
  const [initialValues, setInitialValues] = useState<KpiFormValues>({
    conversions: { start: 0, goal: 0 },
    conversionRate: { start: 0, goal: 0 },
    sessions: { start: 0, goal: 0 },
    engagementRate: { start: 0, goal: 0 },
    userEngagementDuration: { start: 0, goal: 0 },
  });

  useEffect(() => {
    // Find the existing KPI data if available
    const customer = customers.data.find((customer) => customer._id === customerId);
    const kpi = customer?.kpi ? customer.kpi[kpiType] : undefined;

    // set engagementrate to the opposite of the bounceRate if it exists
    if (kpi && !kpi.engagementRate && kpi.bounceRate) {
      kpi.engagementRate = { start: 100 - kpi.bounceRate.start, goal: 100 - kpi.bounceRate.goal };
    }

    if (kpi) {
      setInitialValues({
        conversions: kpi.conversions || { start: 0, goal: 0 },
        conversionRate: kpi.conversionRate || { start: 0, goal: 0 },
        sessions: kpi.sessions || kpi.visitors || { start: 0, goal: 0 },
        engagementRate: kpi.engagementRate || { start: 0, goal: 0 },
        userEngagementDuration: kpi.userEngagementDuration || { start: 0, goal: 0 },
      });
    }
  }, [customerId, kpiType, customers.data]);

  let fieldMap: string[] = [];

  // Case statement to determine which fields to display
  switch (kpiType) {
    case "seo":
      fieldMap = ["sessions", "conversions", "conversionRate", "engagementRate"];
      break;
    case "sem":
      fieldMap = ["sessions", "conversions", "conversionRate", "engagementRate"];
      break;
    case "cro":
      fieldMap = ["engagementRate", "userEngagementDuration", "conversions", "conversionRate"];
      break;
    default:
      break;
  }

  const onSubmit = async (data: KpiFormValues) => {
    try {
      const customer = customers.data.find((customer) => customer._id === customerId);

      if (!customer) {
        throw new Error("Customer not found");
      }

      const updatedKpi = {
        ...customer.kpi,
        [kpiType]: data,
      };

      await client.service("customers").patch(customerId, { kpi: updatedKpi });

      // Close the modal
    } catch (error) {
      console.error("Error submitting form", error);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
      {({ values, handleChange, handleBlur, handleSubmit, dirty }) => (
        <Form onSubmit={handleSubmit}>
          <div className="space-y-2">
            {fieldMap.map((field) => (
              <div className="grid grid-cols-3 gap-4" key={field}>
                <div className="my-auto text-sm text-gray">{field}</div>
                <Input
                  fullWidth
                  size="sm"
                  name={`${field}.start`}
                  labelPlacement="inside"
                  variant="flat"
                  type="number"
                  label="Start"
                  value={values[field as keyof KpiFormValues].start.toString()}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Input
                  fullWidth
                  size="sm"
                  name={`${field}.goal`}
                  labelPlacement="inside"
                  variant="flat"
                  type="number"
                  label="Mål"
                  value={values[field as keyof KpiFormValues].goal.toString()}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            ))}
          </div>

          <div className="flex justify-end my-5">
            <div className="flex">
              {dirty && (
                <Button type="submit" color="primary">
                  Save
                </Button>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
