import { Task } from "../../utils/client";
import { Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@heroui/react";
import { useRenderCell } from "../../hooks/useRenderCell";

type ActivityTableProps = {
  tasks: Task[];
  title: string;
};

export default function ActivityTable({ tasks, title }: ActivityTableProps) {
  const renderCell = useRenderCell();
  const columns = [
    { key: "taskStatus", label: "" },
    { key: "customerId", label: "KUND" },
    { key: "task", label: "AKTIVITET" },
    { key: "deadline", label: "DEADLINE" },
    { key: "timeGiven", label: "TIMMAR" },
    { key: "assignedTo", label: "TILLDELAD TILL" },
    { key: "createdBy", label: "SKAPAD AV" },
    { key: "createdAt", label: "SKAPAD" },
    { key: "updatedAt", label: "UPPDATERAD" },
    { key: "taskActions", label: "" },
  ];
  return (
    <div>
      <Table
        topContent={<div>{title}</div>}
        classNames={{
          wrapper: "shadow-none",
        }}
        className="panel"
        aria-label="Controlled table example with dynamic content"
      >
        <TableHeader columns={columns}>
          {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
        </TableHeader>
        <TableBody emptyContent={"Inga aktiviteter"} items={tasks}>
          {(task) => (
            <TableRow key={task._id.toString()}>
              {(columnKey) => <TableCell>{renderCell(task, columnKey.toString())}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
