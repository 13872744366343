import { GenericHeroTable, GenericColumn, StatusOption } from "../../components/GenericHeroTable";
import { Customerservice, Link } from "../../utils/client";
import { useModal } from "../../hooks/useModal";
import LinkForm from "./LinkForm";

/** 1) Column definitions */
const columns: Array<GenericColumn<Link>> = [
  { uid: "sourceUrl", name: "URL källa", sortable: true },
  { uid: "targetUrl", name: "URL mål", sortable: true },
  { uid: "anchorText", name: "Ankartext", sortable: true },
  { uid: "linkType", name: "Länktyp", sortable: true },
  { uid: "domainAuthority", name: "Domän auktoritet", sortable: true },
  { uid: "datePurchased", name: "Inköpsdatum", sortable: true },
  { uid: "expirationDate", name: "Utgångsdatum", sortable: true },
  { uid: "cost", name: "Kostnad", sortable: true },
  { uid: "invoiceAmount", name: "Fakturabelopp", sortable: true },
];

/** 2) Example status options (adjust to your Link.status values) */
const statusOptions: StatusOption[] = [
  { uid: "all", name: "All" },
  { uid: "active", name: "Active" },
  { uid: "pending", name: "Pending" },
  { uid: "removed", name: "Removed" },
];

/** 3) A simple text filter across selected fields */
function defaultTextFilter(links: Link[], filterValue: string): Link[] {
  const lower = filterValue.toLowerCase();
  return links.filter((link) => {
    return (
      link.sourceUrl?.toLowerCase().includes(lower) ||
      link.targetUrl?.toLowerCase().includes(lower) ||
      link.anchorText?.toLowerCase().includes(lower) ||
      link.notes?.toLowerCase().includes(lower)
    );
  });
}

type LinksTableProps = {
  links: Link[];
  service: Customerservice;
  refreshLinks: () => void;
};

export default function LinksTable({ links, service, refreshLinks }: LinksTableProps) {
  const { showModal } = useModal();

  return (
    <GenericHeroTable<Link>
      onAddNew={() => {
        showModal(<LinkForm onSuccess={refreshLinks} service={service} />, "Skapa länk");
      }}
      addNewLabel="Skapa länk"
      /** The data we want to display */
      items={links}
      /** Our column definitions (see above) */
      columns={columns}
      /** For status-based filtering (optional) */
      statusOptions={statusOptions}
      enableSelection={false}
      onRowClick={(link) => {
        showModal(<LinkForm onSuccess={refreshLinks} service={service} link={link} />, "Redigera Länk");
      }}
      /** Basic text filter & status filter logic */
      onFilter={defaultTextFilter}
      /** Show only some columns by default (optional) */
      /** Sort by datePurchased ascending to start (optional) */
      initialRowsPerPage={10}
      /** Render certain fields custom (dates, status, etc.) */
      renderCell={(item, columnKey) => {
        // For date fields, format them
        if (columnKey === "datePurchased" && typeof item.datePurchased === "number") {
          // E.g. store epoch seconds => item.datePurchased * 1000, or just milliseconds
          const date = new Date(item.datePurchased);
          return date.toLocaleDateString();
        }
        if (columnKey === "expirationDate" && typeof item.expirationDate === "number") {
          const date = new Date(item.expirationDate);
          return date.toLocaleDateString();
        }

        // For cost, maybe show a currency format
        if (columnKey === "cost" && typeof item.cost === "number") {
          return `$${item.cost.toFixed(2)}`;
        }

        // Otherwise, fallback to string
        return String(item[columnKey] ?? "");
      }}
    />
  );
}
