import { Button, Input } from "@heroui/react";
import { Formik, Form } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../context/DataContext";
import * as Yup from "yup"; // Assuming you're using Yup for validation
import { Customer, client } from "../../utils/client";
import { useModal } from "../../hooks/useModal";
import { ObjectId } from "bson";

type ContactFormProps = {
  customerId: string;
  contactId?: string;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email format").required("Email is required"),
  phone: Yup.string(),
  title: Yup.string(),
  role: Yup.string(),
});

export default function ContactForm({ customerId, contactId }: ContactFormProps) {
  const { customers } = useContext(DataContext);
  const { closeModal } = useModal();
  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
    phone: "",
    title: "",
    role: "",
  });

  useEffect(() => {
    if (contactId) {
      // Find the existing contact data if contactId is provided
      const customer = customers.data.find((customer: Customer) => customer._id === customerId);
      const contact = customer?.contacts?.find((contact: any) => contact._id === contactId);
      if (contact) {
        setInitialValues({
          name: contact.name || "",
          email: contact.email || "",
          phone: contact.phone || "",
          title: contact.title || "",
          role: contact.role || "",
        });
      }
    }
  }, [customerId, contactId, customers.data]);

  const onSubmit = async (data: any) => {
    try {
      const customer = customers.data.find((customer) => customer._id === customerId);

      if (!customer) {
        throw new Error("Customer not found");
      }

      let updatedContacts;

      if (contactId) {
        // Update existing contact
        updatedContacts = customer?.contacts?.map((contact) =>
          contact._id === contactId ? { ...contact, ...data } : contact
        );
      } else {
        // Create new contact
        const newContact = { _id: new ObjectId().toString(), ...data }; // Create a new contact with a new ID
        updatedContacts = [...(customer.contacts || []), newContact];
      }
      await client.service("customers").patch(customerId, { contacts: updatedContacts });

      // Close the modal
      closeModal();
    } catch (error) {
      console.error("Error submitting form", error);
    }
  };

  const onDelete = async () => {
    try {
      const customer = customers.data.find((customer) => customer._id === customerId);

      if (!customer) {
        throw new Error("Customer not found");
      }

      const updatedContacts = customer?.contacts?.filter((contact) => contact._id !== contactId);

      await client.service("customers").patch(customerId, { contacts: updatedContacts });

      // Close the modal
      closeModal();
    } catch (error) {
      console.error("Error deleting contact", error);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
      {({ values, handleChange, handleBlur, handleSubmit, dirty }) => (
        <Form onSubmit={handleSubmit}>
          <div className="space-y-2">
            <Input
              fullWidth
              name="name"
              labelPlacement="inside"
              variant="flat"
              type="text"
              label="Namn"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="email"
              labelPlacement="inside"
              variant="flat"
              type="email"
              label="E-post"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="phone"
              labelPlacement="inside"
              variant="flat"
              type="text"
              label="Telefon"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="title"
              labelPlacement="inside"
              variant="flat"
              type="text"
              label="Titel"
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="role"
              labelPlacement="inside"
              variant="flat"
              type="text"
              label="Roll"
              value={values.role}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>

          <div className="flex justify-end mt-5 space-x-5">
            {!dirty && contactId && (
              <Button onClick={onDelete} type="button" color="danger">
                Ta bort
              </Button>
            )}
            {dirty && (
              <Button type="submit" color="primary">
                Spara
              </Button>
            )}
            <Button onClick={closeModal} type="button">
              Avbryt
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
