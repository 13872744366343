// Texts.tsx
import { useEffect, useState } from "react";
import { client, Text } from "../../utils/client";
import { GenericHeroTable, GenericColumn } from "../../components/GenericHeroTable";
import { useRenderCell } from "../../hooks/useRenderCell";

export default function Texts() {
  const [textsArray, setTextsArray] = useState<Text[]>([]);
  const renderCell = useRenderCell();

  useEffect(() => {
    client
      .service("texts")
      .getTextsWithKeywords({})
      .then((res) => {
        setTextsArray(res);
      });
  }, []);

  const columns: Array<GenericColumn<any>> = [
    { uid: "customerId", name: "KUND", sortable: true },
    { uid: "keywords", name: "SÖKORD", sortable: false },
    { uid: "texttitlemetaurl", name: "TEXT", sortable: true },
    { uid: "hours", name: "TIMMAR", sortable: true },
    { uid: "textStatus", name: "STATUS", sortable: true },
    { uid: "deadline", name: "DEADLINE", sortable: true },
    { uid: "userId", name: "COPYWRITER", sortable: true },
    { uid: "surfer.link", name: "SURFER", sortable: true },
    { uid: "docsLink", name: "GOOGLE DOCS", sortable: true },
    { uid: "createdAt", name: "SKAPAD", sortable: true },
    { uid: "updatedAt", name: "UPPDATERAD", sortable: true },
    { uid: "textActions", name: "" },
  ];

  return (
    <div className="space-y-6">
      <h1 className="text-2xl font-medium text-gray">Texter</h1>
      <GenericHeroTable<Text>
        filterByCustomer
        filterByUser
        items={textsArray}
        renderCell={renderCell}
        columns={columns}
        onFilter={(items, filterValue) => {
          const needle = filterValue.toLowerCase();
          return items.filter((row) => row.title?.toLowerCase().includes(needle));
        }}
        statusOptions={[
          { name: "Alla", uid: "all" },
          { name: "Beställd", uid: "1" },
          { name: "Skickad till kund för korrektur", uid: "4" },
          { name: "Klar för publicering", uid: "5" },
          { name: "Publicerad", uid: "6" },
        ]}
        statusKey="status"
        onStatusFilter={(items, selection, statusKey) => {
          if (selection.has("all")) return items;
          return items.filter((row) => selection.has(String(row[statusKey])));
        }}
        enablePagination={true}
        enableSelection={false}
        initialRowsPerPage={25}
        onRowClick={(item) => {
          console.log("Clicked row:", item);
        }}
      />
    </div>
  );
}
