import { useState, useEffect } from "react";
import { Button, Input, Select, SelectItem, Textarea } from "@heroui/react";
import { Formik, Form } from "formik";
import { client, Keyword, Text } from "../../utils/client";
import { useModal } from "../../hooks/useModal";
import SelectUser from "./Fields/SelectUser";

type TextFormProps = {
  text: Text;
};

export default function TextEditForm({ text }: TextFormProps) {
  const { closeModal } = useModal();
  const [error, setError] = useState<string | null>(null);

  // 1) Add state for this text’s keywords
  const [keywords, setKeywords] = useState<Keyword[]>([]);

  useEffect(() => {
    if (!text._id) return;

    // 2) Fetch keywords where `textId` matches this text
    client
      .service("keywords")
      .find({ query: { textId: text._id.toString() } })
      .then((res) => {
        setKeywords(res.data);
      })
      .catch((err) => {
        console.error("Failed to load keywords:", err);
      });
  }, [text._id]);

  const onSubmit = async (data: any) => {
    const statusNum = parseInt(data.status, 10);
    data.status = statusNum;

    if (statusNum >= 4) {
      const trimmedLink = (data.docsLink ?? "").trim();
      if (!trimmedLink) {
        setError("Länk till Google Docs krävs för att kunna skicka texten till kund för korrektur");
        return;
      }
    }

    data.surfer = { ...text.surfer, ...data.surfer };
    await client.service("texts").patch(text._id.toString(), data);
    closeModal();
  };

  const removeText = async () => {
    await client.service("texts").remove(text._id.toString());
    closeModal();
  };

  const statuses = [
    { key: 1, label: "Beställd" },
    { key: 4, label: "Skickad till kund för korrektur" },
    { key: 5, label: "Klar för publicering" },
    { key: 6, label: "Publicerad" },
  ];

  return (
    <Formik
      initialValues={{
        description: text.description,
        url: text.url,
        title: text.title,
        metadescription: text.metadescription,
        docsLink: text.docsLink,
        surfer: {
          link: text.surfer?.link ?? "",
          start: text.surfer?.start ?? 0,
          best: text.surfer?.best ?? 0,
          score: text.surfer?.score ?? 0,
        },
        status: text.status?.toString() ?? "1",
        userId: text.userId,
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ values, handleChange, handleBlur, handleSubmit, dirty, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <div className="space-y-2">
            {/* Existing text fields... */}
            <Textarea
              fullWidth
              name="description"
              labelPlacement="inside"
              variant="flat"
              type="text"
              label="Beskrivning"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {/* ... other Input fields ... */}

            {/* Show keywords as a simple read-only comma-separated list */}
            <Textarea
              fullWidth
              readOnly
              labelPlacement="inside"
              variant="flat"
              label="Keywords"
              value={keywords.map((k) => k.keyword).join(", ")}
            />
            <Input
              fullWidth
              name="url"
              labelPlacement="inside"
              variant="flat"
              type="text"
              label="URL"
              value={values.url}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="title"
              labelPlacement="inside"
              variant="flat"
              type="text"
              label="Titel"
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="metadescription"
              labelPlacement="inside"
              variant="flat"
              type="text"
              label="Meta Description"
              value={values.metadescription}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="surfer.link"
              labelPlacement="inside"
              variant="flat"
              type="text"
              label="Surfer länk"
              value={values.surfer.link ?? ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="docsLink"
              labelPlacement="inside"
              variant="flat"
              type="text"
              label="Länk till Google Docs"
              value={values.docsLink ?? ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="surfer.start"
              labelPlacement="inside"
              variant="flat"
              label="Surfer start"
              type="number"
              value={values.surfer.start?.toString() ?? "0"}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="surfer.best"
              labelPlacement="inside"
              variant="flat"
              label="Surfer bäst"
              type="number"
              value={values.surfer.best?.toString() ?? "0"}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              fullWidth
              name="surfer.score"
              labelPlacement="inside"
              variant="flat"
              label="Surfer score"
              type="number"
              value={values.surfer.score?.toString() ?? "0"}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            {/* NextUI Select usage: specify a value and onChange that sets form field */}
            <Select
              label="Välj status"
              // Convert Formik's string value into a Set
              selectedKeys={new Set([values.status])}
              // NextUI v3 callback
              onSelectionChange={(keys) => {
                // keys is a Set of strings
                const picked = Array.from(keys)[0] ?? "";
                setFieldValue("status", picked);
              }}
            >
              {statuses.map((s) => (
                <SelectItem textValue={s.label} key={s.key.toString()}>
                  {s.label}
                </SelectItem>
              ))}
            </Select>

            <div className="my-2">
              <SelectUser
                name="userId"
                label="Copywriter"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.userId}
              />
            </div>
          </div>

          {error && <div className="my-6 text-sm text-red-500">{error}</div>}

          <div className="flex justify-end mt-5 space-x-5">
            {!dirty && (
              <Button onClick={removeText} type="button" color="danger">
                Ta bort
              </Button>
            )}

            {dirty && (
              <Button type="submit" color="primary">
                Spara
              </Button>
            )}
            <Button onClick={closeModal} type="button">
              Avbryt
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
