import { Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, User } from "@heroui/react";

type CustomerTeamProps = {
  team: any[];
};

export default function CustomerTeam({ team }: CustomerTeamProps) {
  return (
    <Table
      classNames={{
        wrapper: "shadow-none",
      }}
      aria-label="Example static collection table"
    >
      <TableHeader>
        <TableColumn>KUNDTEAM</TableColumn>
        <TableColumn>ROLL</TableColumn>
      </TableHeader>
      <TableBody items={team}>
        {(item) => (
          <TableRow key={item.role}>
            <TableCell>
              <User
                className="bg-transparent"
                avatarProps={{
                  size: "md",
                  radius: "full",
                  src: item.profilePicture,
                  classNames: {
                    base: "bg-transparent",
                  },
                }}
                description={item.email}
                name={item.fullName}
              />
            </TableCell>
            <TableCell>{item.role}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
