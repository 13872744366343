import { LinkData } from "../utils/client";

export function initializeLinkData(link?: Partial<LinkData>): LinkData {
  return {
    // If "customerId" is always required, default to an empty object or string:
    customerId: link?.customerId ?? {},
    sourceUrl: link?.sourceUrl ?? "",
    targetUrl: link?.targetUrl ?? "",
    anchorText: link?.anchorText ?? "",
    linkType: link?.linkType ?? "dofollow",
    domainAuthority: link?.domainAuthority,
    datePurchased: link?.datePurchased ?? Date.now(),
    expirationDate: link?.expirationDate,
    cost: link?.cost ?? 0,
    invoiceAmount: link?.invoiceAmount ?? 0,
    notes: link?.notes ?? "",
  };
}
