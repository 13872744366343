import { client, Task } from "../../utils/client";
import { Button, Checkbox, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@heroui/react";
import { useRenderCell } from "../../hooks/useRenderCell";
import { useContext, useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { DataContext } from "../../context/DataContext";
import TaskForm from "../../components/Forms/TaskForm";
import { useModal } from "../../hooks/useModal";

type CustomerActivitiesProps = {
  customerId: string;
};

export default function CustomerActivities({ customerId }: CustomerActivitiesProps) {
  const { user } = useAuth();
  const { tasks } = useContext(DataContext);
  const [customerTasks, setcustomerTasks] = useState<Task[]>([]);
  const [showCompletedTasks, setShowCompletedTasks] = useState(false);

  const renderCell = useRenderCell();
  const { showModal } = useModal();

  useEffect(() => {
    const fetchcustomerTasks = async () => {
      const customerTasks = await client.service("tasks").find({
        query: {
          customerId: customerId,
          completed: showCompletedTasks ? undefined : false,
        },
      });

      // Sort customerTasks by deadline if deadline is defined else put without deadline last
      customerTasks.data.sort((a, b) => {
        const deadlineA = a.deadline ? a.deadline.valueOf() : Number.MAX_SAFE_INTEGER;
        const deadlineB = b.deadline ? b.deadline.valueOf() : Number.MAX_SAFE_INTEGER;
        return deadlineA - deadlineB;
      });

      setcustomerTasks(customerTasks.data);
    };
    fetchcustomerTasks();
  }, [tasks, showCompletedTasks]);

  const columns = [
    { key: "taskStatus", label: "" },
    { key: "customerId", label: "KUND" },
    { key: "text", label: "AKTIVITET" },
    { key: "deadline", label: "DEADLINE" },
    { key: "assignedTo", label: "TILLDELAD TILL" },
    { key: "createdBy", label: "SKAPAD AV" },
    { key: "taskActions", label: "" },
  ];
  return (
    <div>
      <Table
        topContent={
          <div className="flex justify-between">
            <div className="p-2 my-2 text-xl font-medium text-gray">Aktiviteter</div>
            <div className="flex space-x-4">
              <Checkbox isSelected={showCompletedTasks} onValueChange={setShowCompletedTasks}>
                <div className="text-xs whitespace-nowrap">Visa klara</div>
              </Checkbox>
              <Button
                className="my-auto shrink-0"
                color="primary"
                onClick={() => showModal(<TaskForm customerId={customerId} />, "Skapa aktivitet")}
              >
                Skapa aktivitet
              </Button>
            </div>
          </div>
        }
        classNames={{
          wrapper: "shadow-none",
        }}
        className="panel"
        aria-label="Controlled table example with dynamic content"
      >
        <TableHeader columns={columns}>
          {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
        </TableHeader>
        <TableBody emptyContent={"Inga aktiviteter"} items={customerTasks}>
          {(task) => (
            <TableRow key={task._id.toString()}>
              {(columnKey) => <TableCell>{renderCell(task, columnKey.toString())}</TableCell>}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
