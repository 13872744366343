import { useContext } from "react";
import { Button } from "@heroui/react";
import { DataContext } from "../../context/DataContext";
import { useNavigate } from "react-router-dom";
import { Twelvepoints } from "../../components/Twelvepoints";
import { useModal } from "../../hooks/useModal";
import CustomerForm from "./CustomerForm";
import moment from "moment";
import SendReportButton from "../../components/SendReportButton";
import { GenericHeroTable, GenericColumn } from "../../components/GenericHeroTable";
import { useRenderCell } from "../../hooks/useRenderCell";

export default function Customers() {
  const { customers } = useContext(DataContext);
  const navigate = useNavigate();
  const { showModal } = useModal();

  const renderCell = useRenderCell();

  // Sort the customers by name (as before)
  const CustomerListSorted = customers.data.sort((a, b) => a.name.localeCompare(b.name));

  // Create an array of items that the GenericHeroTable can consume
  const tableItems = CustomerListSorted.filter((customer) => customer.active).map((customer) => ({
    // We’ll store the raw customer in here too, so we can navigate when needed
    _id: customer._id.toString(),
    customer: customer, // The full object if you need it
    name: customer.name,
    activities: customer.activities,
    timebank: customer.timebank,
    value: customer.value,
    user: customer.user,
    createdAt: customer.createdAt,
    twelvePoints: <Twelvepoints customer={customer} />,
    report: customer.reportId && (
      <div className="space-x-2">
        <Button onPress={() => window.open("/report/" + customer.reportId, "_blank")} color="default" size="sm">
          Gå till rapport
        </Button>
        {/* user && user._id.toString() === "667133d2b9a9ea0d3ad91bf6" && (
          <SendReportButton customerId={customer._id.toString()} />
        )*/}
      </div>
    ),
  }));

  // Define the columns (name + uid). Optionally set `sortable: true` if needed.
  const monthName = moment().format("MMMM");

  const columns: Array<GenericColumn<(typeof tableItems)[0]>> = [
    { name: "Kund", uid: "customer", sortable: true },
    { name: "Timmar i " + monthName, uid: "activities", sortable: true },
    { name: "Timbank", uid: "timebank", sortable: true },
    { name: "12p", uid: "twelvePoints", sortable: true },
    { name: "Affärsvärde", uid: "value", sortable: true },
    { name: "Affärsansvarig", uid: "user", sortable: true },
    { name: "Rapport", uid: "report", sortable: true },
    { name: "Skapad", uid: "createdAt", sortable: true },
  ];

  return (
    <>
      <div className="flex justify-between mb-10">
        <h1 className="text-2xl font-medium text-gray">Kunder</h1>
        <Button color="primary" onClick={() => showModal(<CustomerForm />, "Skapa kund")}>
          Ny kund
        </Button>
      </div>
      <GenericHeroTable
        items={tableItems}
        onRowClick={(rowItem) => {
          navigate(`/customers/${rowItem.customer._id.toString()}`);
        }}
        onSort={(a, b, col, direction) => {
          // For a "customer" column, compare by customer.name
          if (col === "customer") {
            const nameA = a.customer?.name ?? "";
            const nameB = b.customer?.name ?? "";
            return direction === "ascending" ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
          }
          // Fallback: some default comparison
          const x = a[col];
          const y = b[col];
          if (y == null) return direction === "ascending" ? -1 : 1;
          if (x == null) return direction === "ascending" ? -1 : 1;
          if (x < y) return direction === "ascending" ? -1 : 1;
          if (x > y) return direction === "ascending" ? 1 : -1;
          return 0;
        }}
        onFilter={(items, filterValue) => {
          const needle = filterValue.toLowerCase();

          return items.filter((row) => {
            const customerName = row.customer?.name?.toLowerCase() ?? "";
            return customerName.includes(needle);
          });
        }}
        columns={columns}
        enablePagination={false}
        enableSelection={false}
        renderCell={renderCell}
        initialRowsPerPage={10}
      />
    </>
  );
}
