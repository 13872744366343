import { Tooltip, Bar, BarChart, ResponsiveContainer, XAxis } from "recharts";
import { client } from "../../utils/client";
import { useEffect, useState } from "react";
import moment from "moment";

export default function NotesChart() {
  const [notesStats, setNotesStats] = useState<any[]>([]);
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="p-4 bg-white border custom-tooltip rounded-xl border-grayLight">
          <p style={{ color: payload[0].fill }} className="label">{`Antal: ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    const fetchNotes = async () => {
      const notes = await client.service("notes").getNotesStats();
      setNotesStats(notes); // Set your state with the aggregated notes stats
    };

    fetchNotes();
  }, []);

  return (
    <div className="w-full h-auto p-6 rounded-lg panel">
      <div className="my-2">
        <div className="text-xl font-medium text-gray">Kundloggar</div>
      </div>
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={notesStats}>
            <Tooltip content={<CustomTooltip />} />
            <Bar
              radius={[5, 5, 0, 0]}
              type="natural"
              barSize={10}
              key={"createdNotes"}
              stackId="a"
              dataKey={"count"}
              fill={"#D5A438"}
            />
            <XAxis
              padding={{ left: 40, right: 20 }}
              tick={{ fill: "#64748b", fontSize: "14", fontWeight: 300 }}
              axisLine={false}
              tickLine={false}
              dataKey="month"
              tickFormatter={(tick) => moment(tick, "YYYY-MM").format("MMM")} // Format to short month name
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
