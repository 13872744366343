import { useEffect, useState } from "react";
import { Customer, Customerservice, client } from "../../utils/client";
import { Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@heroui/react";
import { useRenderCell } from "../../hooks/useRenderCell";
import { useNavigate } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useModal } from "../../hooks/useModal";
import ServiceForm from "../../components/Forms/ServiceForm";

type CustomerServicesProps = {
  customer: Customer;
};
export default function CustomerServices({ customer }: CustomerServicesProps) {
  const [services, setServices] = useState<Customerservice[]>([]);
  const renderCell = useRenderCell();
  const navigate = useNavigate();
  const { showModal } = useModal();

  useEffect(() => {
    // Fetch customer services
    const fetchServices = async () => {
      // @ts-ignore
      const services = await client.service("services").find({ query: { customerId: customer._id.toString() } });
      setServices(services.data);
    };
    fetchServices();
  }, [customer]);

  const columns = [
    { name: "TJÄNSTER", uid: "service" },
    { name: "", uid: "value" },
    { name: "", uid: "usericon" },
  ];

  return (
    <Table
      className="panel"
      classNames={{
        wrapper: "shadow-none",
      }}
      bottomContent={
        <div className="flex justify-center">
          <PlusIcon
            onClick={() => showModal(<ServiceForm customer={customer} />, "Lägg till ny tjänst")}
            className="w-5 h-5 transition cursor-pointer text-foreground-400 hover:text-gray"
          />
        </div>
      }
      selectionMode="single"
      aria-label="Customer services"
    >
      <TableHeader columns={columns}>
        {(column) => (
          <TableColumn key={column.uid} align={column.uid === "actions" ? "center" : "start"}>
            {column.name}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody emptyContent={"Inga tjänster tillagda"} items={services}>
        {(service) => (
          <TableRow
            onClick={() => navigate("/services/" + service._id.toString())}
            className="cursor-pointer"
            key={service._id.toString()}
          >
            {(columnKey: any) => <TableCell>{renderCell(service, columnKey)}</TableCell>}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
