import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Customerservice, Keyword, client } from "../../utils/client";
import {
  Button,
  Input,
  Selection,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@heroui/react";
import { useRenderCell } from "../../hooks/useRenderCell";
import {
  ArrowPathIcon,
  DocumentTextIcon,
  ExclamationTriangleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { useModal } from "../../hooks/useModal";
import { DataContext } from "../../context/DataContext";
import TextOrderForm from "../../components/Forms/TextOrderForm";
import TextLinkForm from "../../components/Forms/TextLinkForm";

type KeywordTableProps = {
  service: Customerservice;
};

export default function KeywordTable({ service }: KeywordTableProps) {
  const { texts } = useContext(DataContext);
  const [keywords, setKeywords] = useState<Keyword[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Selection>(new Set());
  const [filterValue, setFilterValue] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const { showModal } = useModal();

  const hasSearchFilter = Boolean(filterValue);
  const renderCell = useRenderCell();

  const fetchKeywords = async () => {
    const result = await client.service("keywords").find({
      query: { serviceId: service._id.toString(), important: true },
      paginate: {
        default: 1000,
        max: 1000,
      },
    });
    setKeywords(result.data);
  };

  useEffect(() => {
    fetchKeywords();
  }, [service, texts]);

  const filteredItems = useMemo(() => {
    let filteredKeywords = [...keywords];

    // Sort the keywords by keyword.keyword
    filteredKeywords.sort((a, b) => a.keyword.localeCompare(b.keyword));

    if (hasSearchFilter) {
      filteredKeywords = filteredKeywords.filter((keyword) =>
        keyword.keyword.toLowerCase().includes(filterValue.toLowerCase())
      );
    }
    return filteredKeywords;
  }, [keywords, filterValue]);

  const isAnythingSelected = selectedKeys === "all" || (selectedKeys instanceof Set && selectedKeys.size > 0);

  // Convert selectedKeys to an array of keyword objects
  const selectedKeysArray = Array.from(selectedKeys).map((key) =>
    keywords.find((keyword) => keyword._id.toString() === key.toString())
  );

  const onSearchChange = useCallback((value: string) => {
    if (value) {
      setFilterValue(value);
    } else {
      setFilterValue("");
    }
  }, []);

  // Function to update keywords
  const updateKeywords = useCallback(async () => {
    try {
      setIsUpdating(true); // Start loading state
      await client.service("keywords").updateKeywords({ serviceId: service._id.toString() });
      fetchKeywords();
    } catch (error) {
      console.error("Error updating keywords:", error);
    } finally {
      setIsUpdating(false); // End loading state
    }
  }, []);

  const onClear = useCallback(() => {
    setFilterValue("");
  }, []);

  const columns = [
    { key: "keyword", label: "SÖKORD" },
    { key: "position", label: "POSITION" },
    { key: "cpc", label: "CPC" },
    { key: "traffic", label: "TRAFFIC" },
    { key: "volume", label: "VOLUME" },
    { key: "texttitlemetaurl", label: "TEXT" },
    { key: "text.status", label: "STATUS" },
    { key: "surfer.start", label: "START" },
    { key: "surfer.best", label: "BÄST" },
    { key: "surfer.score", label: "SCORE" },
    { key: "keyword.surfer.link", label: "SURFER" },
    { key: "keyword.docsLink", label: "GOOGLE DOCS" },
    { key: "keywordActions", label: "" },
  ];

  return (
    <Table
      classNames={{
        wrapper: "shadow-none",
      }}
      className="panel"
      topContent={
        <div className="flex justify-between h-10 space-x-5">
          <Input
            isClearable
            className="w-full"
            placeholder="Sökord..."
            startContent={<MagnifyingGlassIcon className="w-4 h-4" />}
            value={filterValue}
            onClear={() => onClear()}
            onValueChange={onSearchChange}
          />
          <div className="flex my-auto space-x-5">
            {service.wincherId && (
              <Button
                onClick={updateKeywords}
                startContent={<ArrowPathIcon className="w-4 h-4" />}
                size="sm"
                color="default"
                isDisabled={isUpdating} // Disable button while loading
                isLoading={isUpdating} // Show loading spinner
              >
                Uppdatera sökord
              </Button>
            )}
            {!service.wincherId && (
              <Button
                startContent={<ExclamationTriangleIcon className="w-4 h-4" />}
                size="sm"
                color="default"
                isDisabled={true} // Disable button while loading
              >
                Lägg till Wincher ID
              </Button>
            )}
            {isAnythingSelected && (
              <>
                <Button
                  onClick={() =>
                    showModal(
                      <TextLinkForm service={service} keywords={selectedKeysArray as Keyword[]} />,
                      "Länka till befintlig text"
                    )
                  }
                  startContent={<DocumentTextIcon className="w-4 h-4" />}
                  size="sm"
                  color="default"
                >
                  Länka till befintlig text
                </Button>
                <Button
                  onClick={() =>
                    showModal(
                      <TextOrderForm service={service} keywords={selectedKeysArray as Keyword[]} />,
                      "Skapa textbeställning"
                    )
                  }
                  startContent={<DocumentTextIcon className="w-4 h-4" />}
                  size="sm"
                  color="primary"
                >
                  Skapa text
                </Button>
              </>
            )}
          </div>
        </div>
      }
      selectedKeys={selectedKeys}
      onSelectionChange={setSelectedKeys}
      aria-label="Controlled table example with dynamic content"
      selectionMode="multiple"
    >
      <TableHeader columns={columns}>
        {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
      </TableHeader>
      <TableBody emptyContent={"Inga sökord tillagda"} items={filteredItems}>
        {(keyword) => (
          <TableRow key={keyword._id.toString()}>
            {(columnKey) => <TableCell>{renderCell(keyword, columnKey.toString())}</TableCell>}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
