import React from "react";
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableColumn,
  Input,
  Button,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Pagination,
} from "@heroui/react";
import { Key } from "@react-types/shared";
import SelectCustomer from "./Table/SelectCustomer";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import SelectUser from "./Table/SelectUser";

export interface GenericColumn<T> {
  name: string;
  uid: keyof T;
  sortable?: boolean;
}

export interface StatusOption {
  name: string;
  uid: string;
}

export interface GenericHeroTableProps<T extends { id?: string; _id?: string }> {
  items: T[];
  columns: GenericColumn<T>[];
  renderCell?: (item: T, columnKey: keyof T) => React.ReactNode;
  onFilter?: (items: T[], filterValue: string) => T[];
  onSort?: (a: T, b: T, columnKey: keyof T, direction: "ascending" | "descending") => number;
  /* Built-in features: status filter, pagination, etc. */
  statusOptions?: StatusOption[];
  onStatusFilter?: (items: T[], selection: Set<string>, statusKey: keyof T) => T[];
  statusKey?: keyof T;
  enablePagination?: boolean;
  enableSelection?: boolean;
  initialRowsPerPage?: number;
  initialSort?: { column: keyof T; direction: "ascending" | "descending" };
  onAddNew?: () => void;
  addNewLabel?: string;
  /* Row click (if needed) */
  onRowClick?: (item: T) => void;
  filterByCustomer?: boolean;
  filterByUser?: boolean;
}

function defaultSortFn<T>(a: T, b: T, columnKey: keyof T, direction: "ascending" | "descending"): number {
  const aValue = a[columnKey];
  const bValue = b[columnKey];

  if (aValue < bValue) {
    return direction === "ascending" ? -1 : 1;
  }
  if (aValue > bValue) {
    return direction === "ascending" ? 1 : -1;
  }
  return 0;
}

export function GenericHeroTable<T extends { [key: string]: any }>(props: GenericHeroTableProps<T>) {
  const {
    items,
    columns,
    renderCell,
    onFilter,
    onSort,
    statusOptions = [],
    onStatusFilter,
    statusKey,
    enablePagination = true,
    enableSelection = true,
    initialRowsPerPage = 5,
    initialSort = { column: columns[0].uid, direction: "ascending" },
    onAddNew,
    addNewLabel = "Add New",
    onRowClick,
    filterByCustomer = false,
    filterByUser = false,
  } = props;

  // ------------- STATES -------------
  const [filterValue, setFilterValue] = React.useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);
  const [page, setPage] = React.useState(1);
  const [sortDescriptor, setSortDescriptor] = React.useState(initialSort);
  const [visibleColumns, setVisibleColumns] = React.useState<Set<keyof T>>(new Set(columns.map((c) => c.uid)));
  const [statusFilter, setStatusFilter] = React.useState<Set<string>>(new Set(["all"]));
  const [selectedCustomerId, setSelectedCustomerId] = React.useState<string | null>(null);
  const [selectedUserId, setSelectedUserId] = React.useState<string | null>(null);

  // ------------- DERIVED VALUES -------------
  const hasSearchFilter = Boolean(filterValue);

  // 1) Visible columns
  const headerColumns = React.useMemo(() => {
    return columns.filter((col) => visibleColumns.has(col.uid));
  }, [columns, visibleColumns]);

  // 2) Text search filter
  const searchFilteredItems = React.useMemo(() => {
    if (!hasSearchFilter || !onFilter) return items;
    return onFilter(items, filterValue);
  }, [items, filterValue, onFilter, hasSearchFilter]);

  // 3) Status filter
  const statusFilteredItems = React.useMemo(() => {
    if (!statusKey || !onStatusFilter || statusFilter.has("all")) {
      return searchFilteredItems;
    }
    return onStatusFilter(searchFilteredItems, statusFilter, statusKey);
  }, [searchFilteredItems, statusFilter, onStatusFilter, statusKey]);

  // 4) **NEW** User filter
  const userFilteredItems = React.useMemo(() => {
    if (!filterByUser || !selectedUserId) {
      return statusFilteredItems;
    }
    // Filter by item.userId if present
    return statusFilteredItems.filter((item) => item.userId === selectedUserId);
  }, [statusFilteredItems, filterByUser, selectedUserId]);

  // 5) Sort
  const sortedItems = React.useMemo(() => {
    const { column, direction } = sortDescriptor;
    const sortFn = onSort ?? defaultSortFn; // existing fallback
    // Spread so we don’t mutate in-place
    return [...userFilteredItems].sort((a, b) => sortFn(a, b, column, direction));
  }, [userFilteredItems, sortDescriptor, onSort]);

  // 6) Customer filter
  const customerFilteredItems = React.useMemo(() => {
    if (!filterByCustomer || !selectedCustomerId) return sortedItems;
    return sortedItems.filter((item) => item.customerId === selectedCustomerId);
  }, [sortedItems, filterByCustomer, selectedCustomerId]);

  // 7) Pagination
  const pageItems = React.useMemo(() => {
    if (!enablePagination) return customerFilteredItems;
    const start = (page - 1) * rowsPerPage;
    return customerFilteredItems.slice(start, start + rowsPerPage);
  }, [page, rowsPerPage, customerFilteredItems, enablePagination]);

  const totalPages = enablePagination ? Math.ceil(customerFilteredItems.length / rowsPerPage) : 1;

  React.useEffect(() => {
    setSelectedCustomerId(null);
  }, [items]);

  // ------------- HANDLERS -------------
  const handleSortChange = React.useCallback((descriptor: { column: Key; direction: "ascending" | "descending" }) => {
    setSortDescriptor({
      column: descriptor.column as keyof T,
      direction: descriptor.direction,
    });
    setPage(1);
  }, []);

  const onSelectionChange = React.useCallback((keys: any) => {
    console.log("Selected keys:", keys);
  }, []);

  // ------------- RENDER: topContent -------------
  const topContent = React.useMemo(() => {
    return (
      <div className="flex flex-col gap-4 p-4 bg-white rounded-lg">
        {/* Row 1: Search input, Status, Columns, Add button */}

        <div className="flex items-end justify-between gap-3">
          {/* Search input */}
          <Input
            className=""
            size="lg"
            isClearable
            placeholder="Search..."
            value={filterValue}
            startContent={<MagnifyingGlassIcon className="w-5 h-5 text-default-400" />}
            onClear={() => {
              setFilterValue("");
              setPage(1);
            }}
            onValueChange={(val) => {
              setFilterValue(val);
              setPage(1);
            }}
          />
          {filterByUser && <SelectUser onSelect={setSelectedUserId} />}
          {filterByCustomer && <SelectCustomer onSelect={setSelectedCustomerId} />}
          {/* Status filter dropdown */}
          {!!statusOptions.length && (
            <Dropdown>
              <DropdownTrigger>
                <Button className="my-auto">Status</Button>
              </DropdownTrigger>
              <DropdownMenu
                items={statusOptions}
                selectionMode="single"
                disallowEmptySelection
                closeOnSelect={false}
                selectedKeys={statusFilter}
                onSelectionChange={(keys) => {
                  const next = new Set<string>(Array.from(keys as Set<string>));
                  setStatusFilter(next);
                  setPage(1);
                }}
              >
                {(item: StatusOption) => <DropdownItem key={item.uid}>{item.name}</DropdownItem>}
              </DropdownMenu>
            </Dropdown>
          )}

          {onAddNew && (
            <Button className="my-auto" color="primary" onPress={onAddNew}>
              {addNewLabel}
            </Button>
          )}
        </div>

        {/* Row 2: total items + rows per page */}
        {enablePagination && (
          <div className="flex items-center justify-between">
            <span className="text-default-400 text-small">Total {customerFilteredItems.length} items</span>
            <label className="flex items-center text-default-400 text-small">
              Rows per page:
              <select
                className="ml-1 bg-transparent outline-none text-default-400 text-small"
                value={rowsPerPage}
                onChange={(e) => {
                  setRowsPerPage(Number(e.target.value));
                  setPage(1);
                }}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
            </label>
          </div>
        )}
      </div>
    );
  }, [
    filterValue,
    statusFilter,
    visibleColumns,
    statusOptions,
    sortedItems.length,
    rowsPerPage,
    onAddNew,
    addNewLabel,
    enablePagination,
  ]);

  // ------------- RENDER: bottomContent -------------
  const bottomContent = React.useMemo(() => {
    if (!enablePagination) return null;
    return (
      <div className="mx-auto">
        <div className="flex items-center justify-between px-2 py-2">
          <span className="w-[30%] text-small text-default-400" />
          <Pagination isCompact showControls color="primary" page={page} total={totalPages} onChange={setPage} />
        </div>
      </div>
    );
  }, [page, totalPages, enablePagination]);

  // ------------- RENDER: TABLE -------------
  return (
    <Table
      aria-label="Generic Hero UI table"
      isHeaderSticky
      bottomContent={bottomContent}
      bottomContentPlacement="outside"
      topContent={topContent}
      topContentPlacement="outside"
      sortDescriptor={{
        column: sortDescriptor.column as Key,
        direction: sortDescriptor.direction,
      }}
      onSortChange={handleSortChange}
      selectionMode={enableSelection ? "multiple" : "none"}
      onSelectionChange={enableSelection ? onSelectionChange : undefined}
    >
      <TableHeader columns={headerColumns}>
        {(col) => (
          <TableColumn
            key={String(col.uid)}
            align={col.uid === "actions" ? "center" : "start"}
            allowsSorting={col.sortable}
          >
            {col.name}
          </TableColumn>
        )}
      </TableHeader>

      <TableBody items={pageItems} emptyContent="No items found">
        {(item) => {
          const rowKey = String(item.id || item._id || Math.random());
          return (
            <TableRow
              key={rowKey}
              onClick={() => onRowClick?.(item)}
              className={onRowClick ? "hover:bg-gray-100 cursor-pointer" : ""}
            >
              {(columnKey) => {
                const cellKey = columnKey as keyof T;
                if (renderCell) {
                  return <TableCell>{renderCell(item, cellKey)}</TableCell>;
                }
                return <TableCell>{String(item[cellKey])}</TableCell>;
              }}
            </TableRow>
          );
        }}
      </TableBody>
    </Table>
  );
}
