import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Input, Select, SelectItem, Textarea, Button } from "@heroui/react";
import { client, type Customerservice, type Link } from "../../utils/client";
import { initializeLinkData } from "../../helpers/initializeLinkData";
import DatePicker from "../../components/Forms/Fields/DatePicker";
import { useModal } from "../../hooks/useModal";

type LinkFormProps = {
  service: Customerservice;
  link?: Link;
  onSuccess?: () => void;
};

const validationSchema = Yup.object().shape({
  sourceUrl: Yup.string().required("Source url is required").url("Must be a valid URL"),
  targetUrl: Yup.string().required("Target url is required").url("Must be a valid URL"),
  anchorText: Yup.string().required("Anchor text is required"),
  linkType: Yup.string().required("Link type is required"),
  domainAuthority: Yup.number().nullable(),
  datePurchased: Yup.number().nullable(),
  expirationDate: Yup.number().nullable(),
  cost: Yup.number().nullable(),
  invoiceAmount: Yup.number().nullable(),
  notes: Yup.string(),
});

export default function LinkForm({ service, link, onSuccess }: LinkFormProps) {
  const { closeModal } = useModal();
  const handleSubmit = async (values: any) => {
    try {
      if (link?._id) {
        await client.service("links").patch(link._id.toString(), values);
      } else {
        await client.service("links").create({ ...values, customerId: service.customerId });
      }
      onSuccess?.();
      closeModal();
    } catch (error) {
      console.error("Error saving link:", error);
    }
  };

  const handleDelete = async () => {
    try {
      if (link?._id) {
        await client.service("links").remove(link._id.toString());
        onSuccess?.();
        closeModal();
      }
    } catch (error) {
      console.error("Error deleting link:", error);
    }
  };

  return (
    <div className="p-4 min-w-[300px]">
      <Formik
        initialValues={initializeLinkData(link)}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, dirty }) => (
          <Form onSubmit={handleSubmit} className="space-y-3">
            <Input
              name="sourceUrl"
              label="URL källa"
              variant="flat"
              value={values.sourceUrl}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <Input
              name="targetUrl"
              label="URL mål"
              variant="flat"
              value={values.targetUrl}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <Input
              name="anchorText"
              label="Ankartext"
              variant="flat"
              value={values.anchorText}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <Select
              name="linkType"
              label="Länktyp"
              variant="flat"
              selectedKeys={[values.linkType]}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <SelectItem key="dofollow">dofollow</SelectItem>
              <SelectItem key="nofollow">nofollow</SelectItem>
            </Select>

            <Input
              name="domainAuthority"
              label="Domän auktoritet"
              variant="flat"
              type="number"
              value={values.domainAuthority?.toString() || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <DatePicker
              value={values.datePurchased}
              label="Inköpsdatum"
              onChange={(newTimestamp) => setFieldValue("datePurchased", newTimestamp)}
            />

            <DatePicker
              value={values.expirationDate}
              label="Utgångsdatum"
              onChange={(newTimestamp) => setFieldValue("expirationDate", newTimestamp)}
            />

            <Input
              name="cost"
              label="Kostnad"
              variant="flat"
              type="number"
              value={values.cost?.toString() || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <Input
              name="invoiceAmount"
              label="Fakturabelopp"
              variant="flat"
              type="number"
              value={values.invoiceAmount?.toString() || ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <Textarea
              name="notes"
              label="Anteckningar"
              variant="flat"
              value={values.notes}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <div className="text-xs text-red-500">
              <div>
                <ErrorMessage name="sourceUrl" />
              </div>
              <div>
                <ErrorMessage name="targetUrl" />
              </div>
              <div>
                <ErrorMessage name="anchorText" />
              </div>
              <div>
                <ErrorMessage name="linkType" />
              </div>
            </div>

            <div className="flex justify-end gap-3">
              {link && (
                <Button type="button" color="danger" onPress={handleDelete}>
                  Ta bort
                </Button>
              )}
              {!dirty && (
                <Button type="button" color="default" onPress={closeModal}>
                  Avbryt
                </Button>
              )}
              {dirty && (
                <Button type="submit" color="primary" disabled={!dirty}>
                  {link ? "Uppdatera Länk" : "Skapa länk"}
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
