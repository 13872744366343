import { Select, SelectItem, Avatar } from "@heroui/react";
import { DataContext } from "../../context/DataContext";
import { useContext } from "react";

type SelectUserProps = {
  onSelect: (customerId: string | null) => void;
};

export default function SelectUser({ onSelect }: SelectUserProps) {
  const { users } = useContext(DataContext);

  return (
    <Select
      aria-label={"Välj användare"}
      classNames={{
        base: "max-w-xs",
        trigger: "h-12",
      }}
      selectionMode="single"
      onSelectionChange={(keys) => {
        const selected = Array.from(keys)[0];
        if (!selected || selected === "all") {
          onSelect(null);
        } else {
          onSelect(selected as string);
        }
      }}
      items={users.data}
      placeholder="Välj en användare"
      renderValue={(items) => {
        return items.map((item) => (
          <div key={item.key} className="flex items-center gap-2">
            <Avatar alt={item.data?.fullName} className="flex-shrink-0" size="sm" src={item.data?.profilePicture} />
            <div className="flex flex-col">
              <span>{item.data?.fullName}</span>
              <span className="text-default-500 text-tiny">({item.data?.email})</span>
            </div>
          </div>
        ));
      }}
    >
      {(user) => (
        <SelectItem key={user._id.toString()} textValue={user.fullName}>
          <div className="flex items-center gap-2">
            <Avatar alt={user.fullName} className="flex-shrink-0" size="sm" src={user.profilePicture} />
            <div className="flex flex-col">
              <span className="text-small">{user.fullName}</span>
              <span className="text-tiny text-default-400">{user.email}</span>
            </div>
          </div>
        </SelectItem>
      )}
    </Select>
  );
}
