import { Avatar } from "@heroui/react";
import moment from "moment";
import React, { useEffect, useRef } from "react";

type LogProps = {
  notes: any[];
};
export default function Log({ notes }: LogProps) {
  const activityContainerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    const container = activityContainerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [notes]);

  return (
    <div className="flex flex-col panel h-fit">
      <div className="px-6 pt-6 text-base text-gray">Logg</div>
      <div
        ref={activityContainerRef}
        className="h-auto my-4 space-y-4 overflow-y-auto border-t max-h-96 border-foreground-100 panel"
      >
        <div className="p-6">
          {notes &&
            notes.map((note, index) => (
              <div key={note._id.toString()} className="flex">
                <div className="relative">
                  {index !== notes.length - 1 ? (
                    <span className="absolute top-5 left-5 h-full w-0.5 bg-foreground-100 " aria-hidden="true" />
                  ) : null}

                  <Avatar name={note.user.fullName} src={note.user.profilePicture} />
                </div>
                <div className="flex-1 min-w-0 ml-4">
                  <div>
                    <div className="text-sm font-medium text-gray">{note.user.fullName}</div>
                    <div
                      title={moment(note.createdAt).format("YYYY-MM-DD HH:ss:mm")}
                      className="text-foreground-500 text-small "
                    >
                      {moment(note.createdAt).fromNow()}
                    </div>
                  </div>
                  <div className="text-sm note text-gray">
                    <div className="break-words " dangerouslySetInnerHTML={{ __html: note.text }}></div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
