import { Form, Formik } from "formik";
import { User, client } from "../utils/client";
import { Button, Input } from "@heroui/react";
export default function Admin() {
  const onSubmit = async (data: User) => {
    await client.service("users").create(data);
  };
  return (
    <>
      <div className="flex justify-between mb-10">
        <h1 className="text-4xl text-gray">Admin</h1>
      </div>

      <div className="p-10 bg-white rounded-xl">
        <div className="mb-5 text-gray">Bjud in användare</div>
        <Formik initialValues={{} as User} onSubmit={onSubmit}>
          {({ values, handleChange, handleBlur, dirty, errors, touched }) => (
            <Form>
              <div className="space-y-2">
                <Input
                  fullWidth
                  required
                  name="firstName"
                  labelPlacement="inside"
                  variant="flat"
                  type="text"
                  label="Förnamn"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Input
                  fullWidth
                  required
                  name="lastName"
                  labelPlacement="inside"
                  variant="flat"
                  type="text"
                  label="Efternamn"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Input
                  fullWidth
                  required
                  name="email"
                  labelPlacement="inside"
                  variant="flat"
                  type="text"
                  label="E-post"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              {errors && <div className="text-danger">{}</div>}
              <div className="flex justify-end mt-5">
                <Button type="submit" color="primary">
                  Bjud in
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
